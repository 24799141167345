import React, { Component } from 'react'
import './App.css'
import Button from './button'

import zdj1 from './images/Czy_wiesz_że/1.JPG'
import cwz2 from './images/Czy_wiesz_że/2.JPG'
import cwz3 from './images/Czy_wiesz_że/3.JPG'
import cwz4 from './images/Czy_wiesz_że/4.JPG'
import cwz5 from './images/Czy_wiesz_że/5.JPG'
import cwz6 from './images/Czy_wiesz_że/6.JPG'
import cwz7 from './images/Czy_wiesz_że/7.JPG'

import klsp1 from './images/Każdy_lubi_się_pośmiać/1.JPG'
import klsp2 from './images/Każdy_lubi_się_pośmiać/2.JPG'
import klsp3 from './images/Każdy_lubi_się_pośmiać/3.JPG'
import klsp4 from './images/Każdy_lubi_się_pośmiać/4.JPG'
import klsp6 from './images/Każdy_lubi_się_pośmiać/6.JPG'
import klsp7 from './images/Każdy_lubi_się_pośmiać/7.JPG'
import klsp8 from './images/Każdy_lubi_się_pośmiać/8.JPG'
import klsp9 from './images/Każdy_lubi_się_pośmiać/9.JPG'
import klsp10 from './images/Każdy_lubi_się_pośmiać/10.JPG'

import krzyzowka_1 from './images/policzmy_cos/krzyzowka_1.jpg' 
import krzyzowka_1_rozw from './images/policzmy_cos/krzyzowka_1_rozw.jpg' 
import krzyzowka_2 from './images/policzmy_cos/krzyzowka_2.jpg' 
import krzyzowka_2_rozw from './images/policzmy_cos/krzyzowka_2_rozw.jpg' 
import krzyzowka_3 from './images/policzmy_cos/krzyzowka_3.jpg' 
import krzyzowka_3_rozw from './images/policzmy_cos/krzyzowka_3_rozw.jpg' 
import krzyzowka_4 from './images/policzmy_cos/krzyzowka_4.jpg' 
import krzyzowka_4_rozw from './images/policzmy_cos/krzyzowka_4_rozw.jpg' 

import zsm1 from './images/Z_życia_sławnych_matematyków/1.JPG'
import zsm2 from './images/Z_życia_sławnych_matematyków/2.JPG'
import zsm3 from './images/Z_życia_sławnych_matematyków/3.JPG'
import zsm4 from './images/Z_życia_sławnych_matematyków/4.JPG'
import zsm5 from './images/Z_życia_sławnych_matematyków/5.JPG'
import zsm6 from './images/Z_życia_sławnych_matematyków/6.JPG'


const data = [
  {
    title: "Z życia sławnych matematyków...",
    images: [
      zsm1,
      zsm2,
      zsm3,
      zsm4,
      zsm5,
      zsm6
    ],
  },
  {
    title: "Każdy lubi się pośmiać :)",
    add: "Znalezione w internecie",
    images: [
      klsp1,
      klsp2,
      klsp3,
      klsp4,
      klsp6,
      klsp7,
      klsp8,
      klsp9,
      klsp10
    ],
  },
  {
    title: "A może coś policzymy?",
    images: [
      krzyzowka_1,
      krzyzowka_1_rozw,
      krzyzowka_2,
      krzyzowka_2_rozw,
      krzyzowka_3,
      krzyzowka_3_rozw,
      krzyzowka_4,
      krzyzowka_4_rozw
    ]
  },
  {
    title: "Czy wiesz, że...?",
    images: [
      cwz2,
      cwz3,
      cwz4,
      cwz5,
      cwz6,
      cwz7
    ],
  },
]

class App extends Component {

  render(){
    return(
      <div>
        <div className="MainPage">
          <img className="tlo" src={zdj1} alt="szkola" />
          <div className="nazwa-szkoly">
            <h3>Matematyka w roli głównej</h3>
          </div>
          <hr></hr>
        </div>
        <div className="all-cloud">
          {data.map(item => (
            <Button key={item.title} data={item} />
          ))}
        </div>
      </div>
    )
  }
}


export default App;

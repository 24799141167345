import React, { Component } from 'react'

class Button extends Component {
    constructor(props){
        super(props)
        this.state={
          show: false,
          image: 0
        }
        
        this.handleClick = this.handleClick.bind(this)
        this.handleBack = this.handleBack.bind(this)
        this.handleNext = this.handleNext.bind(this)
      }

    handleClick(){
        if(!this.state.show){
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }
        this.setState({
            show: !this.state.show,
            image: 0
        })
    }

    handleBack(){
        this.setState({
            image: this.state.image > 1 ? this.state.image - 1 : 0
        })
    }

    handleNext(){
        const num = this.props.data.images.length - 1
        this.setState({
            image: num > this.state.image ? this.state.image + 1 : num
        })
    }

    render(){
        return(
            <div>
                <div className="cloud" onClick={this.handleClick}>
                    <h4>{this.props.data.title}</h4>
                </div>
                {this.state.show && (
                    <div className="smallPage">
                        <h2>{this.props.data.title}</h2>
                        {this.props.data.add && (
                            <p className="internet">{this.props.data.add}</p>
                        )}
                        <hr/>
                        <div className="close-container" onClick={this.handleClick}>
                            <div className="leftright"></div>
                            <div className="rightleft"></div>
                        </div>
                        <p>
                            {this.props.data.images && (
                                <img className="imgCloud" src={this.props.data.images[this.state.image]} alt={this.state.image}/>
                            )}
                        </p>

                        {this.props.data.images && this.props.data.images.length > 1 && (
                            <div className="back_next">
                                {0 !== this.state.image && (
                                    <input className="back" type="button" value="<" onClick={this.handleBack}/>)}
                                {this.props.data.images.length - 1 !== this.state.image && 
                                    (<input className="next" type="button" value=">" onClick={this.handleNext}/>)}
                            </div>
                        )}
                    </div>)}
            </div>
        )
    }
}

export default Button